<script>
import { mapActions, mapState } from "vuex";
import useVuelidate from "@vuelidate/core";
import { minLength, required, requiredIf } from "@vuelidate/validators";
import { debounce } from "throttle-debounce";
import MainInput from "@/components/helpers/MainInput.vue";
import MainCheckbox from "@/components/helpers/MainCheckbox.vue";
import MainButton from "@/components/helpers/MainButton.vue";
import MainDropzone from "@/components/helpers/MainDropZone.vue";
import MainSearchWithOptions from "@/components/helpers/MainSearchWithOptions.vue";
import {
  MASK_LETTERS_AND_SYMBOLS_PATTERN,
  MASK_NUMBERS_PATTERN,
  MASK_NAMES_PATTERN,
  MASK_ADDRESS_PATTERN,
  MASK_CITY_PATTERN,
} from "@/package/const/validations-patterns";

export default {
  name: "CertificateOrderForm",

  components: {
    MainDropzone,
    MainButton,
    MainCheckbox,
    MainInput,
    MainSearchWithOptions,
  },

  setup: () => ({ v$: useVuelidate() }),

  data() {
    return {
      form: {
        last_name: "",
        first_name: "",
        middle_name: "",
        birthday_at: "",
        birthplace: "",
        passport_seria: "",
        passport_number: "",
        issued_at: "",
        issued_by: "",
        photo: [],
      },

      city: "",
      house: "",
      block: "",
      flat: "",

      agreement: false,
      isNotSpecifyAddress: false,

      birthplacePoints: [],
      issuedPoints: [],
      addresses: [],

      isTriggeredSet: false,
      isButtonLoading: false,
      maskLettersAndSymbolsPattern: MASK_LETTERS_AND_SYMBOLS_PATTERN,
      maskNumbersPattern: MASK_NUMBERS_PATTERN,
      maskAddressPattern: MASK_ADDRESS_PATTERN,
      maskNamesPattern: MASK_NAMES_PATTERN,
      maskCityPattern: MASK_CITY_PATTERN,
    };
  },

  validations() {
    return {
      form: {
        last_name: { required },
        first_name: { required },
        birthday_at: { required },
        birthplace: { required },
        passport_seria: { required },
        passport_number: { required },
        issued_at: { required },
        issued_by: { required },
        photo: {
          minLength: minLength(1),
          required,
        },
      },
      city: {
        required: requiredIf(() => !this.isNotSpecifyAddress),
      },
      house: {
        required: requiredIf(() => !this.isNotSpecifyAddress),
      },
      flat: {
        required: requiredIf(() => !this.isNotSpecifyAddress),
      },
      isParsingData: false,
    };
  },

  computed: {
    ...mapState({
      suggestedBirthplaces: (state) => state.application.suggestedBirthplaces,
      suggestedIssuedPlaces: (state) => state.application.suggestedIssuedPlaces,
      suggestedAddresses: (state) => state.application.suggestedAddresses,
      application: (state) => state.application.application,
    }),

    formattedAddresses() {
      if (this.addresses === null) return [];

      return this.addresses.map((address) => {
        return address.label;
      });
    },
  },

  watch: {
    form: {
      deep: true,
      handler(value) {
        if (this.isParsingData) return;

        this.saveForm(this);
      },
    },

    "form.birthplace": {
      deep: true,
      handler() {
        if (this.isParsingData) return;

        if (
          !this.maskNamesPattern.tokens.L.pattern.test(this.form.birthplace)
        ) {
          return;
        }

        if (this.form.birthplace && !this.isTriggeredSet) {
          this.loadBirthplaces(this.form.birthplace).then(() => {
            this.birthplacePoints = this.suggestedBirthplaces;
          });
        }

        this.isTriggeredSet = false;
      },
    },

    "form.issued_by": {
      deep: true,
      handler() {
        if (this.isParsingData) return;

        if (
          !this.maskLettersAndSymbolsPattern.tokens.L.pattern.test(
            this.form.issued_by
          )
        ) {
          return;
        }

        if (this.form.issued_by && !this.isTriggeredSet) {
          this.loadIssuedPlaces(this.form.issued_by).then(() => {
            this.issuedPoints = this.suggestedIssuedPlaces;
          });
        }

        this.isTriggeredSet = false;
      },
    },

    city: {
      deep: true,
      handler() {
        this.saveForm(this);
        if (this.isParsingData) return;

        if (!this.maskNamesPattern.tokens.L.pattern.test(this.city)) {
          return;
        }

        if (this.city && !this.isTriggeredSet) {
          this.loadAddressesDebounce();
        }

        this.isTriggeredSet = false;
      },
    },

    house: {
      handler() {
        this.saveForm(this);
      },
    },

    flat: {
      handler() {
        this.saveForm(this);
      },
    },

    block: {
      handler() {
        this.saveForm(this);
      },
    },

    isNotSpecifyAddress(newValue) {
      this.saveForm(this);

      if (newValue) {
        delete this.form.address;
        this.city = "";
        this.house = "";
        this.flat = "";
        this.block = "";
      }
    },
  },

  methods: {
    ...mapActions({
      loadBirthplaces: "application/loadBirthplaces",
      loadIssuedPlaces: "application/loadIssuedPlaces",
      storeApplication: "application/storeApplication",
      loadAddresses: "application/loadAddresses",
      checkLatestApplication: "application/checkLatestApplication",
    }),

    async submit() {
      this.isButtonLoading = true;
      const result = await this.v$.$validate();

      if (result) {
        const data = structuredClone(this.form);

        data.photo = this.form.photo[0].file;

        if (data.address !== undefined) {
          const city = this.city.split(",");

          data.address.city = city[0] || null;
          data.address.street = city[1] || null;
          data.address.house = this.house;
          data.address.block = this.block;
          data.address.flat = this.flat;
        }

        data.middle_name = data.middle_name !== "" ? data.middle_name : null;

        await this.submitForm(data);
      } else {
        this.isButtonLoading = false;
      }
    },

    submitForm: debounce(500, function (data) {
      this.storeApplication(data)
        .then(() => {
          this.$router.push({ name: "Documents" });
        })
        .finally(() => {
          this.isButtonLoading = false;
        });
    }),

    loadAddressesDebounce: debounce(500, function () {
      this.loadAddresses(this.city).then(() => {
        this.addresses = this.suggestedAddresses;
      });
    }),

    setBirthplacePoints(value) {
      this.isTriggeredSet = true;
      this.form.birthplace = value;
      this.birthplacePoints = [];
    },

    setIssuedPoints(value) {
      this.isTriggeredSet = true;
      this.form.issued_by = value;
      this.issuedPoints = [];
    },

    setAddresses(value) {
      this.isTriggeredSet = true;
      let cityField = "";
      const address = this.addresses.find((address) => address.label === value);
      this.form.address = address;

      if (address.city) {
        cityField += address.city;
      }

      if (address.street) {
        cityField += `, ${address.street}`;
      }

      this.city = cityField;
      this.house = address.house ?? "";
      this.flat = address.flat ?? "";
      this.block = address.block ?? "";

      this.addresses = [];
    },

    resetBirthplacePoints() {
      this.form.birthplace = "";
      this.birthplacePoints = [];
    },

    resetIssuedPoints() {
      this.form.issued_by = "";
      this.issuedPoints = [];
    },

    resetAddresses() {
      this.city = "";
      this.addresses = [];
    },

    clearBirthplacePoints() {
      this.birthplacePoints = [];
    },

    clearIssuedPoints() {
      this.issuedPoints = [];
    },

    clearAddresses() {
      this.addresses = [];
    },

    saveForm: debounce(500, (context) => {
      localStorage.setItem(
        "certificateOrderForm",
        JSON.stringify({ ...context.form, photo: [] })
      );

      localStorage.setItem(
        "certificateOrderAddressForm",
        JSON.stringify({
          isNotSpecifyAddress: context.isNotSpecifyAddress,
          city: context.city,
          house: context.house,
          flat: context.flat,
          block: context.block,
        })
      );
    }),
  },

  created() {
    const certificateOrderForm = localStorage.getItem("certificateOrderForm");
    const certificateOrderAddressForm = localStorage.getItem(
      "certificateOrderAddressForm"
    );

    this.isParsingData = true;

    if (certificateOrderForm) {
      this.form = { ...JSON.parse(certificateOrderForm) };
    }

    if (certificateOrderAddressForm) {
      const { isNotSpecifyAddress, city, house, flat, block } = JSON.parse(
        certificateOrderAddressForm
      );

      this.isNotSpecifyAddress = isNotSpecifyAddress;
      this.city = city;
      this.house = house;
      this.flat = flat;
      this.block = block;
    }

    this.$nextTick(() => {
      this.isParsingData = false;
    });
  },

  mounted() {
    this.checkLatestApplication().then(() => {
      if (this.application?.status === "processing") {
        this.$router.push({ name: "Documents" });
      }
    });
  },
};
</script>

<template>
  <form class="certificate-order-form" @submit.prevent="submit">
    <h4>Паспортные данные</h4>

    <p>
      RC&nbsp;Group заботится о безопасности ваших персональных данных. После
      выдачи справки паспортные данные и фотография паспорта удаляются из
      RC&nbsp;OFFICE.
    </p>

    <span class="divider" />

    <div class="certificate-order-form__inputs">
      <MainInput
        v-model="form.last_name"
        :mask="maskNamesPattern"
        label="Фамилия"
        :error="v$.form.last_name.$error"
        :error-message="'Заполните поле'"
        required-icon
      />
      <MainInput
        v-model="form.first_name"
        :mask="maskNamesPattern"
        label="Имя"
        :error="v$.form.first_name.$error"
        :error-message="'Заполните поле'"
        required-icon
      />
      <MainInput
        v-model="form.middle_name"
        :mask="maskNamesPattern"
        label="Отчество"
        :error-message="'Заполните поле'"
      />
      <MainInput
        v-model="form.birthday_at"
        label="Дата рождения"
        mask="##.##.####"
        :error="v$.form.birthday_at.$error"
        :error-message="'Заполните поле'"
        required-icon
      />
      <MainSearchWithOptions
        v-model="form.birthplace"
        :mask="maskCityPattern"
        :background-color="'$light-second'"
        :results="birthplacePoints"
        label="Место рождения"
        required-icon
        :show-icon-search="false"
        :error="v$.form.birthplace.$error"
        :error-message="'Заполните поле'"
        @select="setBirthplacePoints"
        @clearResults="clearBirthplacePoints"
        @clear="resetBirthplacePoints"
      />

      <div class="certificate-order-form__grid-wrapper">
        <MainInput
          v-model="form.passport_seria"
          label="Серия паспорта"
          mask="####"
          :error="v$.form.passport_seria.$error"
          :error-message="'Заполните поле'"
          required-icon
        />
        <MainInput
          v-model="form.passport_number"
          label="Номер паспорта"
          mask="######"
          :error="v$.form.passport_number.$error"
          :error-message="'Заполните поле'"
          required-icon
        />
        <MainInput
          v-model="form.issued_at"
          label="Дата выдачи"
          mask="##.##.####"
          :error="v$.form.issued_at.$error"
          :error-message="'Заполните поле'"
          required-icon
        />
      </div>

      <MainSearchWithOptions
        v-model="form.issued_by"
        :mask="maskAddressPattern"
        :background-color="'$light-second'"
        :results="issuedPoints"
        label="Кем выдан"
        required-icon
        :show-icon-search="false"
        :error="v$.form.issued_by.$error"
        :error-message="'Заполните поле'"
        field-type="textarea"
        @select="setIssuedPoints"
        @clearResults="clearIssuedPoints"
        @clear="resetIssuedPoints"
      />
    </div>

    <div class="certificate-order-form__address address">
      <div class="address__title">
        <h4>Адрес проживания</h4>

        <MainCheckbox
          id="specifyAddress"
          v-model="isNotSpecifyAddress"
          :value="isNotSpecifyAddress"
          class="certificate-order-form__checkbox-specify"
          title="Не указывать"
        />
      </div>

      <template v-if="!isNotSpecifyAddress">
        <div class="address__wrapper">
          <MainSearchWithOptions
            v-model="city"
            :mask="maskCityPattern"
            :background-color="'$light-second'"
            :results="formattedAddresses"
            label="Город и улица"
            required-icon
            :show-icon-search="false"
            :error="v$.city.$error"
            :error-message="'Заполните поле'"
            @select="setAddresses"
            @clearResults="clearAddresses"
            @clear="resetAddresses"
          />

          <div class="address__inputs">
            <MainInput
              v-model="house"
              :mask="maskNumbersPattern"
              label="Дом"
              :error="v$.house.$error"
              :error-message="'Заполните поле'"
              required-icon
            />
            <MainInput v-model="block" label="Корпус" />
            <MainInput
              v-model="flat"
              :mask="maskNumbersPattern"
              label="Квартира"
              :error="v$.flat.$error"
              :error-message="'Заполните поле'"
              required-icon
            />
          </div>
        </div>
      </template>
    </div>

    <h4>Фотография паспорта</h4>

    <p>
      Загрузите фотографию первой страницы паспорта в хорошем качестве без
      бликов и засветов. Паспортные данные должны быть четко видны.
    </p>

    <span class="divider" />

    <MainDropzone
      :files.sync="form.photo"
      :error="v$.form.photo.$error"
      :error-message="'Загрузите фото'"
    />

    <MainCheckbox
      id="agreement"
      v-model="agreement"
      :value="agreement"
      class="certificate-order-form__checkbox-agreement"
      title="Я даю согласие на обработку моих персональных данных"
    />

    <MainButton
      class="certificate-order-form__button"
      title="Заказать"
      color="dark"
      padding="14px 48px"
      type="submit"
      :loading="isButtonLoading"
      :disabled="!agreement"
    />
  </form>
</template>

<style lang="scss" scoped>
.certificate-order-form {
  max-width: 600px;

  @media (max-width: 1023px) {
    max-width: none;
  }

  > h4 {
    @include body-1-bold;
    color: $dark-primary;
    margin-bottom: 8px;
  }

  > p {
    @include text-2;
    color: $dark-fifth;
  }

  > .divider {
    margin: 24px 0;
  }

  &__inputs {
    display: flex;
    flex-direction: column;
    gap: 16px;
    margin-bottom: 32px;
  }

  &__grid-wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    column-gap: 16px;
    align-items: flex-end;

    @media (max-width: 575px) {
      grid-template-columns: 1fr;
      grid-row-gap: 16px;
    }
  }

  &__checkbox-agreement {
    margin-top: 32px;
  }

  &__button {
    margin-top: 32px;
    margin-left: auto;

    @media (max-width: 767px) {
      width: 100% !important;
    }
  }

  .main-input {
    margin-bottom: 0;
  }

  ::v-deep .main-checkbox input + label span {
    max-width: none;
  }

  .address {
    margin-bottom: 32px;

    &__title {
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-bottom: 1px solid $outline-light;
      padding-bottom: 24px;

      > h4 {
        @include body-1-bold;
        color: $dark-primary;
      }
    }

    &__wrapper {
      margin-top: 24px;
    }

    &__inputs {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      column-gap: 16px;
      margin-top: 16px;
    }
  }
}
</style>
